import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import DataSource from '../../DataSource/index';
import RatingButtons from '../RatingButtons/RatingButtons';
import RatingMood from '../RatingMood/RatingMood';
import { SETTINGS } from '../../config';
import auth0Client from '../../Auth';
import { th } from 'date-fns/esm/locale';

const nowCategories = ['overallmood','mood', 'stress', 'sore', 'energy'];

const styles = theme => ({
	ratingButton: {
		margin: theme.spacing.unit
	},
	root: {
		maxWidth: 500,
		padding: theme.spacing.unit * 2,
		margin: 'auto'
	}
});

class Now extends Component {
	constructor(props) {
		super(props);
		this.state = {
			categories: [],
			values: {},
			ids: {},
			items: {}
		};
		this.handleChange = this.handleChange.bind(this);
	}
	async handleChange(event) {
		const category = event.target.name;
		const value = event.target.value;
		let change = {items: this.state.items};

		// is the value the same?
		if (this.state.items[category].value === value) {
			// no change
			return;
		}

		// has it been saved already?
		const data = {
			category, value
		};
		

		if (change.items[category]) {
			data.id = change.items[category].id;
		}

		// do save
		let result = await this.saveItem(data);
		if (result && result.id) {
			change.items[category].id = result.id;
			this.setState(change);
		}
		// TODO handle error
	}

	async saveItem(item) {
		const data = {
			label: 'Feeling right now',
			id: item.id,
			category: item.category,
			value: item.value,
			date: new Date()
		}
		return await DataSource.items.save(data);
		// let url = `${SETTINGS.API_URL}/items`;
		// if (this.state.id) {
		// 	url = `${SETTINGS.API_URL}/item/${this.state.id}`;
		// }
		
		// await axios.post(
		// 	url,
		// 	data,
		// 	{
		// 		headers: { Authorization: `Bearer ${auth0Client.getIdToken()}` }
		// 	}
		// );

		// TODO return _ID and keep track in case they change it
	}

	valueField(category) {
		const { classes } = this.props;
		let field;
		if (category.name === 'mood') {
			field = <RatingMood name="mood" value={this.state.values.mood} onClick={this.handleChange} />;
		} else if (category.valueType === 'buttons') {
			field = (
				<RatingButtons
					key={category.name}
					question={category.valueDescription}
					name={category.name}
					value={this.state.items[category.name].value}
					className={classes.ratingButton}
					onClick={this.handleChange}
					buttons={category.values || []}
				/>
			);
		}
		return field;
	}
	async componentDidMount() {
		let categories = await DataSource.categories.get();

		if (!categories.err) {
			categories = categories.filter(cat => nowCategories.includes(cat.name));
			let change = { categories };
			change.items = {};
			nowCategories.forEach(cat => {
				change.items[cat] = {value: ''};
			})
			this.setState(change);
		}
	}

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				{this.state.categories.map(cat => (
					<div key={cat.name}>{this.valueField(cat)}</div>
				))}
			</div>
		);
	}
}

export default withStyles(styles)(Now);
