import React, {Component} from 'react';
// import {Link} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import auth0Client from '../Auth';
import { Grid, TextField, MenuItem, InputAdornment } from '@material-ui/core';
import {SETTINGS} from '../config';
import ItemCard from '../ItemCard/ItemCard';
import SearchIcon from '@material-ui/icons/Search';
import DataSource from '../DataSource'
//import { th } from 'date-fns/esm/locale';

const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      overflowX: 'auto',
    }
    , margin: {
        marginRight: theme.spacing.unit
    }
  });

class Cards extends Component{
    constructor(props) {
        super(props);
        this.state = {
           items: []
           , categories: []
           , category: ''
           , search: ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.searchTimeout = null;
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value}, this.getItems);
        // this.getItems();
    }
    handleSearchChange(event) {
        window.clearTimeout(this.searchTimeout)
        this.setState({search: event.target.value})
        this.searchTimeout = window.setTimeout(() => this.getItems(), 250);
    }

    async deleteItem(id) {
        // TODO add confirmation prior??
        const headers = {
            headers: { 'Authorization': `Bearer ${auth0Client.getIdToken()}` }
        }
        
        const result = (await axios.delete(`${SETTINGS.API_URL}/item/${id}`, headers));
        if (result.status === 200) {
            // remove from local state
            // TODO should just refetch from server??
            const items = this.state.items.filter(item => item._id !== id);
            this.setState({items: items})
        }
        
    }

    async getCategories() {
        const categories = await DataSource.categories.get();
        if (categories.err) {
            // TODO handle err
        }
        this.setState({categories});
    }
    async getItems() {
        const headers = {
            headers: { 'Authorization': `Bearer ${auth0Client.getIdToken()}` }
        }
        let query = [];
        if (this.state.category) {
            query.push('category=' + this.state.category);
        }
        if (this.state.search){
            query.push('search=' + this.state.search);
        }

        const items = (await axios.get(`${SETTINGS.API_URL}/items?${query.join('&')}`, headers)).data;
        this.setState({
            items
        });
    }
    async componentDidMount() {
        await this.getCategories();
        await this.getItems();  
    }

    render() {
        const { classes } = this.props;
        
        return (
            <div classes={classes.root} style={{padding: '20px 20px'}}>
                <div style={{marginBottom: '20px'}}>
                    <Grid container spacing={8} alignItems="flex-end">
                        <Grid item>
                            <TextField
                                className={classes.margin}
                                id="category"
                                select
                                label="Category"
                                style={{minWidth: '125px'}}
                                value={this.state.category}
                                onChange={this.handleChange}
                                SelectProps={{
                                    MenuProps: {
                                    className: classes.menu,
                                    },name: 'category'
                                }}
                                
                                >
                                    <MenuItem value="">[Any Category]</MenuItem>
                                    {this.state.categories.map(cat => (
                                        <MenuItem key={cat.name} value={cat.name}>{cat.label}</MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                        <Grid item>
                        <TextField
                        className={classes.margin}
                        id="input-with-icon-textfield"
                        label="Tag Search"
                        style={{minWidth: '150px'}}
                        onChange={this.handleSearchChange}
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        }}
                    />
                        </Grid>

                    </Grid>
                    
                    
                </div>
                <Grid container spacing={16}>
                    {this.state.items.map(item => <Grid item key={`ic-${item._id}`} xs={12} sm={6} md={4}><ItemCard item={item} deleteItem={this.deleteItem} /></Grid>)}
                    {/* {this.state.days.map(day => <Grid key={`g-${day.date}`} item xs={12} sm={6} md={4}><DayCard day={day}/></Grid>)} */}
                </Grid>
            </div>
        )
    }
}

Cards.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(Cards);