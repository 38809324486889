import React, {Component} from 'react';
import axios from 'axios';
import auth0Client from '../Auth';
// import CanvasJSReact from '../plugins/canvasjs.react';
import {SETTINGS} from '../config';
import {Line} from 'react-chartjs-2';

// var CanvasJSChart = CanvasJSReact.CanvasJSChart;
import moment from 'moment';
import { Grid } from '@material-ui/core';

class Charts extends Component{
    constructor(props) {
        super(props);
        this.state = {
            days: []
        }
    }

    async componentDidMount() {
        const headers = {
            headers: { 'Authorization': `Bearer ${auth0Client.getIdToken()}` }
        }
        
        const days = (await axios.get(`${SETTINGS.API_URL}/days`, headers)).data;
        this.setState({
            days
        });
    }

    render() {
        
        let parsed = {
            whoop_strain: {
                data: []
                , label: 'Day Strain'
                , borderColor: 'rgba(255,99,132,1)'
                , backgroundColor: 'rgba(255, 99, 132, 1)'
                , fill: false
            }
            , whoop_recovery: {
                data: []
                , label: 'Recovery'
                , borderColor: 'rgba(54, 162, 235, 1)'
                , backgroundColor: 'rgba(54, 162, 235, 1)'
                , fill: false
            }
            , whoop_sleep: {
                data: []
                , label: 'Sleep'
                , borderColor: 'rgba(255, 206, 86, 1)'
                , backgroundColor: 'rgba(255, 206, 86, 1)'
                , fill: false
            }
            , exercise: {
                data: []
                , label: 'Exercise'
                , borderColor: 'rgba(75, 192, 192, 1)'
                , backgroundColor: 'rgba(75, 192, 192, 1)'
                , fill: false
            }
            , mood: {
                data: []
                , label: 'Mood'
                , borderColor: 'rgba(153, 102, 255, 1)'
                , backgroundColor: 'rgba(153, 102, 255, 1)'
                , fill: false
            }
        }
        let labels = [];
        let hasExercise = false;
        let hasMood = false;
        this.state.days.forEach(day => {
            if (day.whoop) {
                parsed.whoop_strain.data.push(day.whoop.value);
                parsed.whoop_recovery.data.push(day.whoop.meta.recovery || null);
                parsed.whoop_sleep.data.push(day.whoop.meta.sleep || null);
            }
            if (day.exercise) {
                hasExercise = hasExercise || !!day.exercise.value;
                parsed.exercise.data.push(day.exercise.value || null)
            } else {
                parsed.exercise.data.push(null)
            }
            if (day.mood) {
                hasMood = hasMood || !!day.mood.value;
                parsed.mood.data.push(day.mood.value || null)
            } else {
                parsed.mood.data.push(null)
            }

            labels.push(moment(day.date).format('M/D'));
        })
        console.log(parsed);
/*
            {
                    label: '# of Votes',
                    data: [12, 19, 3, 5, 2, 3],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255,99,132,1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }
*/


            let data = {
                labels: labels, //["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
                datasets: [parsed.whoop_strain, parsed.whoop_recovery, parsed.whoop_sleep, parsed.exercise, parsed.mood]
            }
            return (
                <div>
                    <Grid container>
                    
                    </Grid>
                    <Line data={data}/>
                </div>
                
            // <div>
            //     <CanvasJSChart options = {options}
            //         /* onRef={ref => this.chart = ref} */
            //     />
            //     {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
            // </div>
            )
    }
}

export default Charts;