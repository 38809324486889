import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, IconButton, CardActions, Collapse, Typography, Chip, Avatar, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import ExerciseIcon from '@material-ui/icons/FitnessCenter';
import TagIcon from '@material-ui/icons/LocalOffer';
import HealthIcon from '@material-ui/icons/FavoriteBorder';
import SickIcon from '@material-ui/icons/LocalHospital';
import FoodIcon from '@material-ui/icons/Restaurant';
import WhoopIcon from '@material-ui/icons/Watch';
import AlcoholIcon from '@material-ui/icons/LocalBar';
import MoodIcon from '@material-ui/icons/Mood';
// import Mood1 from '@material-ui/icons/SentimentVeryDissatisfied';
// import Mood2 from '@material-ui/icons/SentimentDissatisfied';
// import Mood3 from '@material-ui/icons/SentimentSatisfied';
// import Mood4 from '@material-ui/icons/SentimentVerySatisfied';
import EditIcon from '@material-ui/icons/Edit';
import CopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './ItemCard.css';

const styles = theme => ({
	card: {
		minWidth: 275
	},
	title: {
		fontSize: 14
	},
	actions: {
		display: 'flex'
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	},
	chip: {
		margin: theme.spacing.unit,
		textTransform: 'capitalize'
	},
	avatar: {
		backgroundColor: '#AAA9AB'
	}
});

function moodIcon(day) {
	if (day.category === 'mood') {
		let a = null;
		if (day.value === 1) {
			//a = <Mood1 style={{color:'#EF3340', fontSize: '3.5em', verticalAlign:'middle'}}/>
			a = (
				<FontAwesomeIcon
					icon="angry"
					style={{ color: '#EF3340', fontSize: '3.5em', verticalAlign: 'middle' }}
				/>
			);
		} else if (day.value === 2) {
			//a = <Mood2 style={{color:'#FFCA3A', fontSize: '3.5em', verticalAlign:'middle'}}/>
			a = (
				<FontAwesomeIcon
					icon="frown"
					style={{ color: '#1982C4', fontSize: '3.5em', verticalAlign: 'middle' }}
				/>
			);
		} else if (day.value === 3) {
			// a = <Mood3 style={{color:'#FF9F1C', fontSize: '3.5em', verticalAlign:'middle'}}/>
			a = <FontAwesomeIcon icon="meh" style={{ color: '#FFCA3A', fontSize: '3.5em', verticalAlign: 'middle' }} />;
		} else if (day.value === 4) {
			// a = <Mood4 style={{color:'#8AC926', fontSize: '3.5em', verticalAlign:'middle'}}/>
			a = (
				<FontAwesomeIcon
					icon="smile"
					style={{ color: '#8AC926', fontSize: '3.5em', verticalAlign: 'middle' }}
				/>
			);
		} else if (day.value === 5) {
			// a = <Mood4 style={{color:'#8AC926', fontSize: '3.5em', verticalAlign:'middle'}}/>
			a = (
				<FontAwesomeIcon
					icon="grin-beam"
					style={{ color: '#8AC926', fontSize: '3.5em', verticalAlign: 'middle' }}
				/>
			);
		}
		return a;
	}
	return null;
}

function categoryIcon(category, subcategory) {
	let icon = null;
	switch (category) {
		case 'exercise':
			icon = <ExerciseIcon />;
			break;
		case 'food':
			icon = <FoodIcon />;
			break;
		case 'mood':
			icon = <MoodIcon />;
			break;
		case 'alcohol':
			icon = <AlcoholIcon />;
			break;
		case 'tag':
			icon = <TagIcon />;
			break;
		case 'sick':
			icon = <SickIcon />;
			break;
		case 'health':
			icon = <HealthIcon />;
			break;
		case 'whoop':
			icon = <WhoopIcon />;
			break;
		default:
			icon = null;
			break;
	}
	return icon;
}

function SimpleCard(props) {
	const { classes } = props;
	let item = props.item || {
		tags: ['tags', 'asdf'],
		_id: '5c7afc8552acb68179caa4b0',
		created: '2019-03-02T21:58:29.923Z',
		updated: '2019-03-02T21:58:29.924Z',

		user: 'auth0|5c674b6257eb5111ec8d3227',
		category: 'exercise',
		value: 1112,
		meta: {
			rpe: '11',
			calories: '21',
			postexercisefeel: '31',
			hr: '41',
			maxhr: '51',
			pace: '61',
			elevation: '71',
			distance: '81'
		},
		date: '2019-03-01T22:11:00.000Z',
		label: 'titleasdf plus 1',
		subcategory: 'outdoor run',
		notes: 'alskdjf aslkdf \n\n alskdjflaksdjfaslkdfj asldkfj asdlfk',
		__v: 0
	};

	return (
		<Card className={classes.card}>
			<CardHeader
				title={item.label || item.category}
				subheader={moment(item.date).format('ddd MMM DD')}
				action={
					<div>
						{item.category === 'mood' ? (
							moodIcon(item)
						) : (
							<span
								style={{
									fontSize: '1.5em',
									display: 'inline-block',
									height: '100%',
									verticalAlign: 'middle'
								}}
							>
								{item.value}
							</span>
						)}
						<IconButton
							onClick={props.onExpandClick}
							className={props.expanded ? classes.expandOpen : classes.expand}
						>
							<ExpandMoreIcon />
						</IconButton>
					</div>
				}
				avatar={<Avatar className={classes.avatar}>{categoryIcon(item.category)}</Avatar>}
			/>

			<Collapse in={props.expanded} timeout="auto" unmountOnExit>
				<CardContent>
					{item.meta && (
						<div>
							<Typography variant="h5">Metas</Typography>
							{Object.keys(item.meta).map(name => {
								return (
									<div key={name}>
										{name} : {item.meta[name]}
									</div>
								);
							})}
						</div>
					)}
					{item.notes && (
						<div>
							<Typography variant="h5">Notes</Typography>
							<Typography paragraph>{item.notes}</Typography>
						</div>
					)}
					{item.tags.map(tag => (
						<Chip key={tag} label={tag} className={classes.chip} />
					))}
				</CardContent>
				<CardActions>
					<Grid container justify="space-evenly">
						<IconButton
							onClick={() => {
								props.deleteItem(item._id);
							}}
						>
							<DeleteIcon />
						</IconButton>
						<IconButton component={Link} to={`/edit/${item._id}`}>
							<EditIcon />
						</IconButton>
						<IconButton component={Link} to={`/new/${item._id}`}>
							<CopyIcon />
						</IconButton>
					</Grid>
				</CardActions>
			</Collapse>
		</Card>
	);
}

class ItemCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expaned: false
		};
		this.onClick = this.onClick.bind(this);
		this.handleExpandClick = this.handleExpandClick.bind(this);
	}
	handleClose() {
		this.setState({ anchorEl: null, expaned: false });
	}
	onClick(event) {
		this.setState({ expanded: true });
	}
	handleExpandClick() {
		this.setState({ expaned: !this.state.expaned });
	}
	render() {
		return (
			<SimpleCard
				expanded={this.state.expaned}
				classes={this.props.classes}
				onClick={this.onClick}
				deleteItem={this.props.deleteItem}
				onExpandClick={this.handleExpandClick}
				viewClick={() => {
					this.props.viewClick();
				}}
				item={this.props.item}
			/>
		);
	}
}

ItemCard.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ItemCard);
