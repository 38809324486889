import React, { Component } from 'react';
import moment from 'moment';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core/styles';
import './Calendar.css';
import { Button, Grid, Typography } from '@material-ui/core';
import DataSource from '../../DataSource/index';

function computeCal(year, month) {
	const firstOfMonth = moment([year, month, 1]);
	const endOfMonth = moment(firstOfMonth).endOf('month');
	const firstOfWeek = moment(firstOfMonth).startOf('week');
	const endOfLastWeek = moment(endOfMonth).endOf('week');

	const output = [];
	let day = moment(firstOfWeek);
	while (day.isBefore(endOfLastWeek)) {
		output.push({
			date: day.format('MM/DD/YYYY'),
			dateRaw: day.toString(),
			day: day.date(),
			month: day.month() + 1,
			year: day.year()
		});
		day.add(1, 'days');
	}

	return output;
}

const styles = theme => ({
	icon: {
		padding: 2,
		color: theme.palette.primary.main
	},
	dot: {
		display: 'inline-block',
		width: 12,
		height: 12,
		margin: '1px',
		borderRadius: 6
	}
});

const colors = {
	exercise: '#3cb44b',
	food: '#f58231',
	whoop: '#bfef45',
	mood: '#ffe119',
	stress: '#42d4f4',
	tag: '#4363d8',
	tags: '#4363d8',
	alcohol: '#e6194B',
	sleep: '#911eb4',
	health: '#f032e6',
	energy: '#e6beff',
	blank: '#ffffff'
};

class Calendar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			days: [],
			month: new Date().getMonth(),
			year: new Date().getFullYear(),
			today: moment().format('MM/DD/YYYY'),
			show: {}
		};
		this.nextMonth = this.nextMonth.bind(this);
		this.previousMonth = this.previousMonth.bind(this);
	}

	async getDays() {
		const data = await DataSource.days.get({});

		if (Array.isArray(data)) {
			let show = this.uniqueCategories(data);
			this.setState({ days: data, show });
		}
	}
	findDay(date) {
		return this.state.days.find(day => date === day.date);
	}
	monthName(mo) {
		return moment([this.state.year, mo, 1]).format('MMMM');
	}
	cellClick(event) {}
	nextMonth() {
		let date = moment([this.state.year, this.state.month, 1]).add(1, 'months');
		this.setState(
			{
				year: date.year(),
				month: date.month()
			},
			() => {
				this.getDays();
			}
		);

		// TODO refresh days data
	}
	previousMonth() {
		let date = moment([this.state.year, this.state.month, 1]).subtract(1, 'months');
		this.setState(
			{
				year: date.year(),
				month: date.month()
			},
			() => {
				this.getDays();
			}
		);
		// TODO refresh days data
	}
	generateIcon(category, classes) {
		return <div className={classes.dot} style={{ backgroundColor: colors[category] || '' }} />;
	}
	generateCell(date, classes) {
		const day = this.findDay(date);
		const icons = [];
		if (day) {
			Object.keys(colors).forEach(cat => {
				if (day[cat] && this.state.show[cat]) {
					icons.push(this.generateIcon(cat, classes));
				}
			});
		} else {
			icons.push(this.generateIcon('blank', classes));
		}
		return (
			<Grid container justify="space-evenly">
				{icons}
			</Grid>
		);
	}
	uniqueCategories(days) {
		let categories = {};
		days.forEach(day => {
			Object.keys(day).forEach(cat => {
				if (cat !== 'date') {
					categories[cat] = true;
				}
			});
		});
		return categories;
		// this.setState({ show: categories });
	}
	generateLegend(classes) {
		return Object.keys(this.state.show).map(cat => (
			<div
				onClick={() => this.legendClick(cat)}
				style={{
					display: 'inline-block',
					marginRight: 8,
					textDecoration: this.state.show[cat] ? '' : 'line-through',
					cursor: 'pointer'
				}}
			>
				{this.generateIcon(cat, classes)}
				<span>{cat}</span>
			</div>
		));
	}
	async componentDidMount() {
		this.getDays();
	}
	legendClick(category) {
		const show = this.state.show;
		show[category] = !show[category];
		this.setState({ show });
	}
	render() {
		const { classes } = this.props;
		const days = computeCal(this.state.year, this.state.month);
		const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

		return (
			<div className="Calendar__container">
				<Grid container justify="space-evenly" style={{ marginBottom: 8, marginTop: 8 }}>
					{this.generateLegend(classes)}
				</Grid>
				<Grid container justify="space-between">
					<Button onClick={this.previousMonth}>Previous</Button>
					<Typography variant="h6">{this.monthName(this.state.month)}</Typography>
					<Button onClick={this.nextMonth}>Next</Button>
				</Grid>
				{daysOfWeek.map(day => (
					<div key={`dow-${day}`} className="Calendar__cell_container">
						<div className="Calendar__cellheader">{day}</div>
					</div>
				))}
				{days.map((day, ix) => (
					<div
						key={ix}
						className={`Calendar__cell_container ${day.date === this.state.today ? 'today' : ''}`}
						onClick={this.cellClick}
					>
						<div className="Calendar__cell">
							<div className="Calendar__cell_date">{moment(day.dateRaw).format('D')}</div>
							<div style={{ padding: 4 }}>{this.generateCell(day.date, classes)}</div>
						</div>
					</div>
				))}
			</div>
		);
	}
}
export default withStyles(styles)(Calendar);
