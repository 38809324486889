import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import moment from 'moment';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from 'material-ui-pickers';
import ChipInput from 'material-ui-chip-input';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import NumPadIcon from '@material-ui/icons/Dialpad';
import { InputAdornment, IconButton, Divider } from '@material-ui/core';

import Dialog from '../NumPad/NumPad';
import { SETTINGS } from '../config';
import auth0Client from '../Auth';
import RatingButtons from '../components/RatingButtons/RatingButtons';
import RatingMood from '../components/RatingMood/RatingMood';
import DataSource from '../DataSource';

import './ItemEdit.css';

const styles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing.unit * 2
	},
	adornedEnd: {
		padding: 0
	},
	adornedStart: {
		padding: 0
	},
	positionEnd: {
		padding: '0 0'
	},
	datePicker: {
		width: '100%'
	},
	ratingButton: {
		margin: theme.spacing.unit
	}
});

class ItemEdit extends Component {
	constructor(props) {
		super(props);
		const user = auth0Client.getProfile().sub;
		const { id } = props.match.params;

		this.state = {
			disabled: false,
			user: user,
			category: '',
			subcategory: '',
			tags: [],
			suggestions: [],
			tagSuggestions: [],
			selectedCategory: null,
			date: moment().format('MM/DD/YYYY'),
			showNumPad: false,
			numPadTarget: null,
			formValid: false,
			label: '',
			value: '',
			meta: {},
			id: id,
			item: {
				// TODO make this work
				category: '',
				subcategory: '',
				tags: [],
				date: moment().format('MM/DD/YYYY'),
				label: '',
				value: '',
				meta: {},
				id: id,
				notes: ''
			},
			categories: []
		};

		this.hideNumPad = this.hideNumPad.bind(this);
		this.showNumPad = this.showNumPad.bind(this);
		this.moveDate = this.moveDate.bind(this);
		this.handleTagAdd = this.handleTagAdd.bind(this);
		this.handleTagDelete = this.handleTagDelete.bind(this);
	}
	/*
        HANDLERS
    */
	showNumPad(target) {
		this.setState({ showNumPad: true, numPadTarget: target });
	}
	hideNumPad(val) {
		const target = this.state.numPadTarget;
		// TODO handle meta
		this.handleChange({
			target: {
				name: target,
				value: val
			}
		});
		this.setState({ showNumPad: false, numPadTarget: null });
	}
	handleTagAdd(tag) {
		this.addTag(tag);
	}

	handleTagDelete(tag, ix) {
		const tags = this.state.tags.slice();
		tags.splice(ix, 1);
		this.setState({ tags: tags });
	}

	handleChange = event => {
		const key = event.target.name;
		const value = event.target.value;

		let change = {
			[key]: value
		};
		if (key === 'category') {
			change.selectedCategory = this.state.categories.find(cat => cat.name === value);
			change.meta = {};
			change.subcategory = '';
		}
		change.formValid = !!(this.state.category || change.category) && !!(this.state.value || change.value);
		this.setState(change, async () => {
			if (change.category || change.subcategory) {
				await this.getTagSuggestions(); // TODO make this async
			}
		});
	};

	handleMetaChange = event => {
		const key = event.target.name;
		const value = event.target.value;

		let change = { meta: this.state.meta || {} };
		change.meta[key] = value;
		if (value === '') {
			delete change.meta[key];
		}

		this.setState(change);
	};

	addTag(id) {
		const tags = this.state.tags.slice();
		tags.push(id);
		this.setState({ tags });
	}

	/* 
    SERVER INTERACTIONS
*/

	async submit() {
		if (!this.state.formValid) {
		} else {
			this.setState({
				disabled: true
			});
			const data = {
				label: this.state.label,
				category: this.state.category,
				value: this.state.value,
				subcategory: this.state.subcategory,
				tags: this.state.tags,
				notes: this.state.notes,
				meta: this.state.meta,
				date: this.state.date,
				id: this.state.id
			}

			let result = await DataSource.items.save(data);
			if (result.id) {
				this.props.history.push('/daily');
			} else if (result.err) {
				alert(result.err);
			}
			// TODO handle error better
			
			// let url = `${SETTINGS.API_URL}/items`;
			// if (this.state.id) {
			// 	url = `${SETTINGS.API_URL}/item/${this.state.id}`;
			// }

			// await axios.post(
			// 	url,
			// 	data,
			// 	{
			// 		headers: { Authorization: `Bearer ${auth0Client.getIdToken()}` }
			// 	}
			// );

			
		}
	}

	async componentDidMount() {
		let category, subcategory;
		if (this.props.location.search) {
			let qs = queryString.parse(this.props.location.search);
			category = qs.category && qs.category.toString();
			subcategory = qs.subcategory && qs.subcategory.toString();
		}
		const headers = {
			headers: { Authorization: `Bearer ${auth0Client.getIdToken()}` }
		};
		let change = {};
		let suggestions = [];
		let categories = [];
		try {
			// suggestions = (await axios.get(`${SETTINGS.API_URL}/tags`, headers)).data;
			categories = (await axios.get(`${SETTINGS.API_URL}/categories`, headers)).data;
		} catch (ex) {
			// TODO handle err
		}
		// let suggestions = (await axios.get(`${SETTINGS.API_URL}/tags`, headers)).data;
		suggestions = suggestions.map(suggestion => suggestion._id);
		change = {
			suggestions,
			categories
		};

		if (this.state.id) {
			let item = (await axios.get(`${SETTINGS.API_URL}/item/${this.state.id}`, headers)).data;
			if (item) {
				change.label = item.label;
				change.value = item.value;
				change.category = item.category;
				change.subcategory = item.subcategory || '';
				change.tags = item.tags || [];
				change.notes = item.notes;
				change.meta = item.meta;
				change.date = item.date;
				change.selectedCategory = categories.find(cat => cat.name === item.category);
			}
		} else {
			if (category) {
				change.selectedCategory = categories.find(cat => cat.name === category);
				change.category = category;
				if (subcategory && change.selectedCategory.subcategories) {
					let exists = change.selectedCategory.subcategories.find(sub => sub.name === subcategory);
					if (exists) {
						change.subcategory = subcategory;
					}
				}
			}
		}
		change.formValid = !!change.category && !!change.value;
		this.setState(change, () => {
			if (change.category) {
				this.getTagSuggestions();
			}
		});
	}

	generateMetas() {
		let metas = [];
		const category = this.state.categories.find(cat => cat.name === this.state.category);
		if (!category || !category.meta || !category.meta.length) return '';
		metas = category.meta.slice();
		if (this.state.subcategory && category.subcategories) {
			const sub = category.subcategories.find(cat => cat.name === this.state.subcategory);
			if (sub && sub.meta && sub.meta.length) {
				metas = [...metas, ...sub.meta];
			}
		}
		return metas.map(item => {
			const id = item.name;
			const label = item.label;
			let helperText = item.description || '';
			if (item.valueRange) {
				helperText += ` (${item.valueRange})`;
			}
			let val = '';
			if (this.state.meta && this.state.meta[item.name]) {
				val = this.state.meta[item.name];
			}
			return (
				<Grid key={id} item xs={6} sm={4} md={3}>
					<TextField
						id={id}
						label={label}
						name={id}
						margin="dense"
						type="number"
						helperText={helperText}
						value={val}
						onChange={this.handleMetaChange}
						variant="outlined"
					/>
				</Grid>
			);
		});
	}
	async getTagSuggestions() {
		const headers = {
			headers: { Authorization: `Bearer ${auth0Client.getIdToken()}` }
		};
		try {
			let query = [];
			if (this.state.category) {
				query.push(`category=${this.state.category}`);
			}
			if (this.state.subcategory) {
				query.push(`subcategory=${this.state.subcategory}`);
			}

			let suggestions = (await axios.get(`${SETTINGS.API_URL}/tags?${query.join('&')}`, headers)).data;
			this.setState({ tagSuggestions: suggestions });
		} catch (ex) {
			// TODO handle error or just fail gracefully as it is non-critical
		}
	}
	hasSubcategory() {
		return (
			this.state.selectedCategory &&
			this.state.selectedCategory.subcategories &&
			this.state.selectedCategory.subcategories.length
		);
	}

	valueHelper() {
		if (!this.state.selectedCategory) {
			return;
		}
		const cat = this.state.selectedCategory;
		let helperText = cat.valueDescription;
		if (helperText && cat.valueRange) {
			helperText += ` (${cat.valueRange})`;
		} else if (!helperText) {
			helperText = cat.valueRange;
		}
		return helperText;
	}
	valueLabel() {
		let label = 'Value';
		if (this.state.selectedCategory) {
			label = this.state.selectedCategory.valueLabel || label;
		}
		return label;
	}
	moveDate(increment) {
		this.setState({
			date: moment(this.state.date)
				.add(increment, 'days')
				.format('MM/DD/YYYY')
		});
	}
	handleDateChange = date => {
		this.setState({ date: date });
	};
	valueField(classes) {
		let field = (
			<TextField
				id="value"
				name="value"
				type="number"
				label={this.valueLabel()}
				// margin="dense"
				value={this.state.value}
				helperText={this.valueHelper()}
				onChange={this.handleChange}
				variant="outlined"
				InputLabelProps={{ shrink: true }}
				fullWidth
				InputProps={{
					classes: { adornedEnd: 'adorned' },
					endAdornment: (
						<InputAdornment position="end">
							<IconButton onClick={() => this.showNumPad('value')}>
								<NumPadIcon />
							</IconButton>
						</InputAdornment>
					)
				}}
			/>
		);
		if (this.state.category === 'mood') {
			field = <RatingMood name="value" value={this.state.value} onClick={this.handleChange} />;
		} else if (this.state.selectedCategory && this.state.selectedCategory.valueType === 'buttons') {
			field = (
				<RatingButtons
					name="value"
					question={this.state.selectedCategory.valueDescription}
					value={this.state.value}
					className={classes.ratingButton}
					onClick={this.handleChange}
					buttons={this.state.selectedCategory.values || []}
				/>
			);
		}
		return field;
	}
	render() {
		const { classes } = this.props;
		return (
			<div style={{ padding: '8px' }}>
				<Grid container alignItems="center" className={classes.root} spacing={16}>
					<Grid item xs={12} md={6}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Grid className={classes.datePicker} container justify="space-around">
								<DatePicker
									margin="normal"
									label="Date"
									variant="outlined"
									value={this.state.date}
									onChange={this.handleDateChange}
								/>
								<TimePicker
									margin="normal"
									label="Time"
									variant="outlined"
									value={this.state.date}
									onChange={this.handleDateChange}
								/>
							</Grid>
						</MuiPickersUtilsProvider>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							id="category"
							select
							label="Category"
							value={this.state.category}
							onChange={this.handleChange}
							SelectProps={{
								MenuProps: {
									className: classes.menu
								},
								name: 'category'
							}}
							fullWidth
							variant="outlined"
						>
							{this.state.categories.map(cat => (
								<MenuItem key={cat.name} value={cat.name}>
									{cat.label}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					{this.hasSubcategory() && (
						<Grid item xs={12} sm={6}>
							<TextField
								id="subcategory"
								select
								label="Sub Category"
								value={this.state.subcategory}
								onChange={this.handleChange}
								SelectProps={{
									MenuProps: {
										className: classes.menu
									},
									name: 'subcategory'
								}}
								fullWidth
								variant="outlined"
							>
								{this.state.selectedCategory.subcategories.map(cat => (
									<MenuItem style={{ textTransform: 'capitalize' }} key={cat.name} value={cat.name}>
										{cat.label || cat.name}
									</MenuItem>
								))}
							</TextField>
						</Grid>
					)}
					<Grid item xs={12}>
						{this.valueField(classes)}
						{/* <TextField
							id="value"
							name="value"
							type="number"
							label={this.valueLabel()}
							// margin="dense"
							value={this.state.value}
							helperText={this.valueHelper()}
							onChange={this.handleChange}
							variant="outlined"
							InputLabelProps={{ shrink: true }}
							fullWidth
							InputProps={{
								classes: { adornedEnd: 'adorned' },
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={() => this.showNumPad('value')}>
											<NumPadIcon />
										</IconButton>
									</InputAdornment>
								)
							}}
						/> */}
					</Grid>
					{/* <Grid
						item
						xs={12}
						style={
							this.state.selectedCategory && this.state.selectedCategory.valueType !== 'buttons'
								? { display: 'none' }
								: {}
						}
					>
						<RatingButtons
							name="value"
							onClick={this.handleChange}
							buttons={this.selectedCategory.values || []}
						/>
					</Grid> */}
					<Divider variant="middle" />
					<Grid item xs={12} container>
						{this.generateMetas()}
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="label"
							label="Title"
							name="label"
							margin="dense"
							value={this.state.label}
							helperText="Optional"
							fullWidth
							onChange={this.handleChange}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12}>
						<ChipInput
							value={this.state.tags}
							// onChange={this.handleTagChange}
							onAdd={this.handleTagAdd}
							onDelete={this.handleTagDelete}
							newChipKeyCodes={[13, 188]}
							variant="outlined"
							label="Tags"
							fullWidth
							helperText="Use simple tags to find trends.  Example for Food : Breakfast, (yogurt bagel oj)"
							margin="dense"
						/>
						<div style={{ padding: 16 }}>
							{this.state.tagSuggestions.map(tag => (
								<Button key={tag._id} size="small" onClick={() => this.addTag(tag._id)}>
									{tag._id}
								</Button>
							))}
						</div>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="notes"
							label="Notes"
							name="notes"
							margin="dense"
							helperText=""
							variant="outlined"
							fullWidth
							multiline={true}
							onChange={this.handleChange}
						>
							{this.state.notes}
						</TextField>
					</Grid>
					<Grid item xs={12} container justify="flex-end" style={{ marginBottom: 24 }}>
						<Button color="default" variant="contained" style={{ marginRight: '20px' }}>
							Cancel
						</Button>
						<Button
							color="primary"
							variant="contained"
							onClick={() => {
								this.submit();
							}}
						>
							Save
						</Button>
					</Grid>
				</Grid>

				<Dialog open={this.state.showNumPad} onDone={this.hideNumPad} />
			</div>
		);
	}
}
ItemEdit.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(ItemEdit));
