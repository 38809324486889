import React, {Component} from 'react';
// import {Link} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import auth0Client from '../Auth';
import { Grid } from '@material-ui/core';
import {SETTINGS} from '../config';
import DayCard from '../DayCard/DayCard';

const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      overflowX: 'auto',
    }
  });

class Cards extends Component{
    constructor(props) {
        super(props);
        this.state = {
           days: []
        }
    }

    async componentDidMount() {
        const headers = {
            headers: { 'Authorization': `Bearer ${auth0Client.getIdToken()}` }
        }
        
        const days = (await axios.get(`${SETTINGS.API_URL}/days`, headers)).data;
        this.setState({
            days
        });
    }

    render() {
        const { classes } = this.props;
        
        return (
            <div classes={classes.root} style={{padding: '20px 20px'}}>
                <Grid container spacing={24}>
                    {this.state.days.map(day => <Grid key={`g-${day.date}`} item xs={12} sm={6} md={4}><DayCard day={day}/></Grid>)}
                </Grid>
            </div>
        )
    }
}

Cards.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(Cards);