import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

const styles = theme => ({});

class RatingButtons extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: this.props.value
		};
	}
	onClick(val) {
		this.setState({ value: val });
		this.props.onClick({
			target: {
				name: this.props.name,
				value: val
			}
		});
	}
	render() {
		return (
			<div>
				<h4>{this.props.question}</h4>
				<Grid container justify="space-evenly">
					{this.props.buttons.map(button => (
						<Button
							key={button.value}
							onClick={() => this.onClick(button.value)}
							variant={button.value === this.state.value ? 'contained' : 'outlined'}
							color="secondary"
							style={{ margin: 4 }}
						>
							{button.text}
						</Button>
					))}
				</Grid>
			</div>
		);
	}
}

RatingButtons.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RatingButtons);
