import axios from 'axios';
import auth0Client from '../Auth';
import { SETTINGS } from '../config';

const cache = {
	categories: null
};

function baseHeaders() {
	return {
		headers: { Authorization: `Bearer ${auth0Client.getIdToken()}` }
	};
}

const categories = {
	get: async opts => {
		if (opts && !opts.refresh && cache.categories) {
			return cache.categories.slice();
		}

		const headers = baseHeaders();
		let result;
		try {
			result = (await axios.get(`${SETTINGS.API_URL}/categories`, headers)).data;
			cache.categories = result;
		} catch (ex) {
			// TODO handle err
			result = {
				err: ex.toString()
			};
		}
		return result;
	}
};

const days = {
	get: async opts => {
		const headers = baseHeaders();
		let result;
		const query = [];
		if (opts.start) {
			query.push(`start=${opts.start}`);
		}
		if (opts.end) {
			query.push(`end=${opts.end}`);
		}
		try {
			result = (await axios.get(`${SETTINGS.API_URL}/days?${query.join('&')}`, headers)).data;
			cache.categories = result;
		} catch (ex) {
			// TODO handle err
			result = {
				err: ex.toString()
			};
		}
		return result;
	}
};

const items = {
	save: async data => {
		// TODO validation?
		let url = `${SETTINGS.API_URL}/items`;
		if (data.id) {
			url = `${SETTINGS.API_URL}/item/${data.id}`;
		}

		let result;
		try {
			result = (await axios.post(url, data, baseHeaders())).data;
		} catch (ex) {
			// TODO handle err
			result = {
				err: ex.toString()
			};
		}
		return result;
	}
};

const DataSource = {
	categories,
	days, items
};

export default DataSource;
