import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/AddCircleOutlined';
import AccountIcon from '@material-ui/icons/AccountBox';
import DailyIcon from '@material-ui/icons/ViewModule';
import HomeIcon from '@material-ui/icons/Home';
import ListingIcon from '@material-ui/icons/ViewList';
import ChartIcon from '@material-ui/icons/MultilineChart';
import NewIcon from '@material-ui/icons/Add';
import { MenuItem, Menu, Fade, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DataStore from './DataSource';
import auth0Client from './Auth';

const styles = theme => ({
	root: {
		flexGrow: 1
	},
	grow: {
		flexGrow: 1
	},
	menuButton: {
		marginLeft: -12
	},
	logo: {
		marginLeft: 12,
		width: '120px',
		verticalAlign: 'middle'
	},
	buttonIcon: {
		marginLeft: theme.spacing.unit
	},
	desktopOnly: {
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block'
		},
		textAlign: 'center'
	}
});

class NavBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showMenu: false,
			openDialog: false,
			showDrawer: false,
			categories: []
		};
		this.openMenu = this.openMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
		this.openDrawer = this.openDrawer.bind(this);
		this.closeDrawer = this.closeDrawer.bind(this);
	}
	openMenu(ev) {
		this.setState({ showMenu: true, el: ev.currentTarget });
	}
	closeMenu() {
		this.setState({ showMenu: false });
	}
	openDrawer() {
		this.setState({ showDrawer: true });
	}
	closeDrawer() {
		this.setState({ showDrawer: false });
	}

	async componentWillMount() {
		const categories = await DataStore.categories.get();
		this.setState({ categories });
		// TODO handle err returned from cat.get
	}

	render() {
		const { classes } = this.props;
		const signOut = () => {
			auth0Client.signOut();
			this.props.history.replace('/');
		};

		const isAuth = auth0Client.isAuthenticated();
		const list = [];
		this.state.categories.forEach(cat => {
			list.push({ name: cat.name, label: cat.label, id: cat.name, category: cat.name });
			if (cat.subcategories) {
				cat.subcategories.forEach(sub => {
					list.push({
						...sub,
						parent: cat.name,
						id: cat.name + sub.name,
						category: cat.name,
						subcategory: sub.name
					});
				});
			}
		});

		return (
			<div className={classes.root}>
				<AppBar position="static">
					<Toolbar>
						{isAuth && (
							<IconButton
								id="btn-menu"
								onClick={this.openDrawer}
								className={classes.menuButton}
								color="inherit"
								aria-label="Menu"
							>
								<MenuIcon />
							</IconButton>
						)}
						<Menu
							id="main-menu"
							anchorEl={this.state.el}
							open={this.state.showMenu}
							onClose={this.closeMenu}
							TransitionComponent={Fade}
						>
							<MenuItem to="/" component={Link} onClick={this.closeMenu}>
								Home
							</MenuItem>
							<MenuItem to="/list" component={Link} onClick={this.closeMenu}>
								Listing
							</MenuItem>
							<MenuItem to="/daily" component={Link} onClick={this.closeMenu}>
								Daily
							</MenuItem>
							<MenuItem to="/new" component={Link} onClick={this.closeMenu}>
								New Item
							</MenuItem>

							<MenuItem to="/chart" component={Link} onClick={this.closeMenu}>
								Chart
							</MenuItem>
							<MenuItem
								onClick={() => {
									signOut();
								}}
							>
								Logout
							</MenuItem>
						</Menu>
						{/* <div><img src="/logo-white.png" style={{width:'100px'}}/></div> */}
						<div classes={{ flexGrow: 1 }} />
						<img src="/logo-white.png" className={classes.logo} alt="qnti.life logo" />
						<Typography variant="h6" color="inherit" className={classes.grow} />
						{isAuth && (
							<Button component={Link} to="/daily" color="inherit" className={classes.desktopOnly}>
								Daily
							</Button>
						)}
						{isAuth && (
							<Button component={Link} to="/list" color="inherit" className={classes.desktopOnly}>
								List
							</Button>
						)}

						{isAuth && (
							<Button component={Link} to="/new" color="inherit" aria-label="New Item">
								Add
								<AddIcon className={classes.buttonIcon} />
							</Button>
						)}

						{/* <IconButton 
                            component={Link}
                            to="/new"
                            // containerElement={<Link to="/edit" />}
                            // linkButton={true}
                            className={classes.menuButton} 
                            color="inherit" 
                            aria-label="Add">
                            <AddIcon />
                        </IconButton> */}
						{!isAuth && (
							<Button color="inherit" onClick={auth0Client.signIn}>
								Login
							</Button>
						)}
						{isAuth && (
							<IconButton color="inherit" aria-label="Account">
								<AccountIcon />
								{/* <Button color="inherit">{auth0Client.getProfile().name}</Button> */}
							</IconButton>
						)}
					</Toolbar>
					{isAuth && (
						<div style={{ padding: '0 0 10px 20px' }}>
							{/* <Link to="/now">How ya feeling right now?</Link> */}
							<Button component={Link} to="/now" color="inherit" aria-label="How ya feeling now?">
								How ya feeling right now?
							</Button>
						</div>
					)}
				</AppBar>
				<Drawer anchor="left" open={this.state.showDrawer} onClose={this.closeDrawer}>
					<div style={{ width: 250 }} onClick={this.closeDrawer}>
						<List>
							<ListItem button component={Link} to="/">
								<ListItemIcon>
									<HomeIcon />
								</ListItemIcon>
								<ListItemText>Home</ListItemText>
							</ListItem>
							<ListItem button component={Link} to="/new">
								<ListItemIcon>
									<NewIcon />
								</ListItemIcon>
								<ListItemText>New Entry</ListItemText>
							</ListItem>
							<ListItem button component={Link} to="/daily">
								<ListItemIcon>
									<DailyIcon />
								</ListItemIcon>
								<ListItemText>Daily View</ListItemText>
							</ListItem>
							<ListItem button component={Link} to="/calendar">
								<ListItemIcon style={{ paddingLeft: 4 }}>
									<FontAwesomeIcon style={{ fontSize: 20 }} icon="calendar-alt" />
								</ListItemIcon>
								<ListItemText>Calendar</ListItemText>
							</ListItem>
							<ListItem button component={Link} to="/list">
								<ListItemIcon>
									<ListingIcon />
								</ListItemIcon>
								<ListItemText>Entry List</ListItemText>
							</ListItem>
							<ListItem button component={Link} to="/chart">
								<ListItemIcon>
									<ChartIcon />
								</ListItemIcon>
								<ListItemText>Chart</ListItemText>
							</ListItem>
							<Divider variant="middle" />
							{list.map(cat => (
								<ListItem
									button
									key={cat.id}
									component={Link}
									to={`/new?category=${cat.category}&subcategory=${cat.subcategory}`}
								>
									<ListItemText>
										<div style={cat.parent ? { paddingLeft: 20 } : {}}>{cat.label || cat.name}</div>
									</ListItemText>
								</ListItem>
							))}
						</List>
					</div>
				</Drawer>
			</div>
		);
	}
}

NavBar.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(NavBar));
