import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import auth0Client from './Auth';
import {SETTINGS} from './config';
import axios from 'axios';

class Callback extends Component {


  async componentDidMount() {
    await auth0Client.handleAuthentication();
    const token = auth0Client.getIdToken();

    
    const headers = {
      headers: { 'Authorization': `Bearer ${token}` }
    }
  
    const result = (await axios.post(`${SETTINGS.API_URL}/register`,{}, headers));
    if (result.status === 200) {
      // TODO catch errors
    }
    
    // console.log(auth0Client.getProfile());

    this.props.history.replace('/');
  }

  render() {
    return (
      <p>Loading profile...</p>
    );
  }
}

export default withRouter(Callback);