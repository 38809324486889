import React, { Component } from 'react';
import { Paper, Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => {
	return {
		chip: { margin: theme.spacing.unit / 2 }
	};
};
class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const { classes } = this.props;
		return (
			<div>
				<Paper style={{ padding: '30px' }}>
					<div>
						qnti.life quantifies your life, allowing you to make informed decisions about how your daily
						life affects you. Through tracking food, exercise, rest, sleep, sickness, mood, you can easily
						find trends in what makes you feel great while eradicating the bad.
					</div>
					<div>
						<img src="/logo-black.png" style={{ width: '100%' }} alt="qnti.life logo" />
					</div>
					<p>
						Track known data like exercise, meals, whoop, mood with metrics (a numeric value) but also track
						seemingly random data by tagging. These tags will help expose trends and patterns.
					</p>
					<h5>Tagging</h5>
					<p>
						For example, I would like to find trends in the foods that I eat. While I can track calories,
						I'm more interested in the exact foods that I eat and how they affect me. So, when I add a Food
						entry for breakfast, I use the Tags feature to enter the major parts of the meal. So my
						breakfast tags this morning looks like...
					</p>

					{/* <Grid container spacing={24} justify="space-around"> */}
					<div>
						<Chip label="yogurt" className={classes.chip} />
						<Chip label="oj" className={classes.chip} />
						<Chip label="smoothie" className={classes.chip} />
						<Chip label="raspberries" className={classes.chip} />
						<Chip label="blueberries" className={classes.chip} />
						<Chip label="spinach" className={classes.chip} />
						<Chip label="protein powder" className={classes.chip} />
						<Chip label="banana" className={classes.chip} />
					</div>

					<p>Created my free logo at LogoMakr.com</p>
				</Paper>
			</div>
		);
	}
}

Home.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Home);
