import React from 'react'
import { Card, CardHeader, Grid, IconButton } from '@material-ui/core';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
// import Moment from 'react-moment';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExerciseIcon from '@material-ui/icons/FitnessCenter';
import FoodIcon from '@material-ui/icons/Restaurant';
import AlcoholIcon from '@material-ui/icons/LocalBar';
import MoodIcon from '@material-ui/icons/Mood';
// import Mood1 from '@material-ui/icons/SentimentVeryDissatisfied';
// import Mood2 from '@material-ui/icons/SentimentDissatisfied';
// import Mood3 from '@material-ui/icons/SentimentSatisfied';
// import Mood4 from '@material-ui/icons/SentimentVerySatisfied';

import './DayCard.css';

const styles = {
    card: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    tag: {
        padding: '4px 4px'
        , borderRadius: '4px'
        , backgroundColor: 'silver'
	},
	categoryIcon: {
		fontSize: '1.4em'
		, verticalAlign: 'middle'
		, marginRight: '6px'
	}
  };

function whoop(day) {
	if (day.whoop) {
		return (
			<Grid item xs={12} container>
				<Grid item xs={6}>
					Whoop
				</Grid>
				<Grid item xs={6}>
					<div style={{textAlign:'right'}}>
						<span className="DayCard__value">{day.whoop.value}</span>
						&nbsp;|&nbsp;
						<span className="DayCard__value">{day.whoop.meta.recovery}</span>
						&nbsp;|&nbsp;
						<span className="DayCard__value">{day.whoop.meta.sleep}</span>
					</div>
				</Grid>
			</Grid>
			);
	}
	return null;
}

function exercise(day, classes) {
	if (day.exercise) {
		return (
			<Grid item xs={12} container>
				<Grid item xs={6}>
					<ExerciseIcon className={classes.categoryIcon}/>
					Exercise
				</Grid>
				<Grid item xs={6}>
					<div style={{textAlign:'right'}}>
						<span className="DayCard__value">{day.exercise.value}</span> mins
					</div>
				</Grid>
			</Grid>
		);
	}
	return null;
}
function food(day, classes) {
	if (day.food) {
		let bclass = 'DayCard__food';
		let lclass = 'DayCard__food';
		let dclass = 'DayCard__food';
		let sclass = 'DayCard__food';
		if (day.food.breakfast) {
			bclass += ' medium';
		}
		if (day.food.lunch) {
			lclass += ' medium';
		}
		if (day.food.dinner) {
			dclass += ' medium';
		}
		if (day.food.snack) {
			sclass += ' medium';
		}
		return (
			<Grid item xs={12} container>
				<Grid item xs={6}>
					<FoodIcon className={classes.categoryIcon}/>
					Food
				</Grid>
				<Grid item xs={6}>
					<div style={{textAlign:'right'}}>
						<div className={bclass}>B</div>
						<div className={lclass}>L</div>
						<div className={dclass}>D</div>
						<div className={sclass}>S</div>
					</div>
				</Grid>
			</Grid>
		);
	}
	return null;
}

function mood(day, classes) {
	if (day.mood) {
		let a = null;
		if (day.mood.value === 1) {
			// a = <Mood1 style={{color:'#EF3340', fontSize: '1.8em'}}/>
			a = <FontAwesomeIcon icon="angry" style={{color:'#EF3340', fontSize: '1.8em', verticalAlign:'middle'}}/>
		} else if (day.mood.value === 2) {
			// a = <Mood2 style={{color:'#FFCA3A', fontSize: '1.8em'}}/>
			a = <FontAwesomeIcon icon="frown" style={{color:'#1982C4', fontSize: '1.8em', verticalAlign:'middle'}}/>
		} else if (day.mood.value === 3) {
			// a = <Mood3 style={{color:'#FF9F1C', fontSize: '1.8em'}}/>
			a = <FontAwesomeIcon icon="meh" style={{color:'#FFCA3A', fontSize: '1.8em', verticalAlign:'middle'}}/>
		} else if (day.mood.value === 4) {
			// a = <Mood4 style={{color:'#8AC926', fontSize: '1.8em'}}/>
			a = <FontAwesomeIcon icon="smile" style={{color:'#8AC926', fontSize: '1.8em', verticalAlign:'middle'}}/>
		} else if (day.mood.value === 5) {
			a = <FontAwesomeIcon icon="grin-beam" style={{color:'#8AC926', fontSize: '1.8em', verticalAlign:'middle'}}/>
		}
		return (
			<Grid item xs={12} container>
				<Grid item xs={6}>
					<MoodIcon className={classes.categoryIcon}/>
					Mood
				</Grid>
				<Grid item xs={6}>
					<div style={{textAlign:'right'}}>
						{a}
					</div>
				</Grid>
			</Grid>
		)
	}
	return null;
}

function alcohol(day, classes) {
	if (day.alcohol) {
		return (
			<Grid item xs={12} container>
				<Grid item xs={6}>
					<AlcoholIcon className={classes.categoryIcon}/>
					Alcohol
				</Grid>
				<Grid item xs={6}>
					<div style={{textAlign:'right'}}>
						<span className="DayCard__value">{day.alcohol.value}</span>
					</div>
				</Grid>
			</Grid>
		);
	}
	return null;
}

function tags(s) {
	let a = {};
	s.forEach(b => {
		if (!a[b]) {
			a[b] = 1;
		} else {
			a[b]++;
		}
	})

	return Object.keys(a);
}
function DayCard(props) {
	const {classes} = props;
	const day = props.day;
    // const day = {
	// 	date: new Date()
	// 	, tags: ['weighted vest', 'hot tub', 'cold plunge', '']
	// 	, whoop: {
	// 		value: 15.6
	// 		, meta: {
	// 			recovery: 72
	// 			, sleep: 87
	// 		}
	// 	}
	// 	, exercise: { value: 71}
	// 	, alcohol: {value: 3}
	// 	, health: {value: 3, meta: {hrv: 59, rhr: 49}}
    // }
    return (
        <Card className={classes.card}>
            <CardHeader 
                title={moment(day.date).format('ddd MMM DD')}
				subheader={moment(day.date).fromNow()}
				action={
					<IconButton><MoreVertIcon/></IconButton>
				}
            />
            <CardContent>
                <Grid container spacing={16}>
					{whoop(day)}
					{exercise(day, classes)}
					{food(day, classes)}
					{alcohol(day, classes)}
					{mood(day, classes)}
					<Grid item xs={12}>
						<div className="DayCard__tags">
							{tags(day.tags).map(tag => <span className="tag" key={tag}>{tag}</span>)}
						</div>
					</Grid>
					{/* <Grid item xs={6}>
						<MoodIcon className={classes.categoryIcon}/>
						Mood
					</Grid>
					<Grid item xs={6}>
						<div style={{textAlign:'right'}}>
							<Mood3 style={{color:'red', fontSize:'2em'}}/>
						</div>
					</Grid>
					<Grid item xs={6}>
						<MoodIcon className={classes.categoryIcon}/>
						Health
					</Grid>
					<Grid item xs={6}>
						<div style={{textAlign:'right'}}>
								<span className="DayCard__value">{day.health.meta.hrv}</span>
								&nbsp;|&nbsp;
								<span className="DayCard__value">{day.health.meta.rhr}</span>
						</div>
					</Grid> */}
                </Grid>
            </CardContent>
        </Card>
    );
}


DayCard.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles)(DayCard);