import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = theme => ({
	icon: {
		padding: 6,
		cursor: 'pointer'
	}
});

class RatingMood extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: this.props.value
		};
	}
	onClick(val) {
		this.setState({ value: val });
		this.props.onClick({
			target: {
				name: this.props.name,
				value: val
			}
		});
	}
	color(val) {
		// TODO hover colors
		const colors = ['#EF3340', '#1982C4', '#FFCA3A', '#8AC926', '#8AC926'];
		if (val === this.state.value) {
			return colors[val - 1];
		}
		return '#DCDCDC';
	}
	render() {
		const { classes } = this.props;
		return (
			<div>
				<h4>How are you feeling?</h4>
				<Grid container justify="space-evenly">
					<IconButton onClick={() => this.onClick(1)} className={classes.icon}>
						<FontAwesomeIcon
							icon="angry"
							style={{ color: this.color(1), fontSize: '2em', verticalAlign: 'middle' }}
						/>
					</IconButton>
					<IconButton onClick={() => this.onClick(2)} className={classes.icon}>
						<FontAwesomeIcon
							icon="frown"
							style={{ color: this.color(2), fontSize: '2em', verticalAlign: 'middle' }}
						/>
					</IconButton>
					<IconButton onClick={() => this.onClick(3)} className={classes.icon}>
						<FontAwesomeIcon
							icon="meh"
							style={{ color: this.color(3), fontSize: '2em', verticalAlign: 'middle' }}
						/>
					</IconButton>
					<IconButton onClick={() => this.onClick(4)} className={classes.icon}>
						<FontAwesomeIcon
							icon="smile"
							style={{ color: this.color(4), fontSize: '2em', verticalAlign: 'middle' }}
						/>
					</IconButton>
					<IconButton onClick={() => this.onClick(5)} className={classes.icon}>
						<FontAwesomeIcon
							icon="grin-beam"
							style={{ color: this.color(5), fontSize: '2em', verticalAlign: 'middle' }}
						/>
					</IconButton>
				</Grid>
			</div>
		);
	}
}

RatingMood.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RatingMood);
