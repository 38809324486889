import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faSmile,
	faFrown,
	faAngry,
	faMeh,
	faGrinBeam,
	faDumbbell,
	faBurn,
	faUtensils,
	faCopy,
	faTag,
	faPlusSquare,
	faCalendarAlt
} from '@fortawesome/free-solid-svg-icons';
import SecuredRoute from './SecuredRoute';
import NavBar from './NavBar';
import Callback from './Callback';
import ItemEdit from './ItemEdit/ItemEdit';
import Chart from './Charts/Charts';
import Home from './Home';
import Cards from './Cards/Cards';
import ItemCardList from './ItemCardList/ItemCardList';
import Calendar from './components/Calendar/Calendar';
import Now from './components/Now/Now';
import auth0Client from './Auth';

library.add(faSmile);
library.add(faFrown);
library.add(faAngry);
library.add(faMeh);
library.add(faGrinBeam);
library.add(faDumbbell);
library.add(faBurn);
library.add(faUtensils);
library.add(faCopy);
library.add(faPlusSquare);
library.add(faTag);
library.add(faCalendarAlt);

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkingSession: true
		};
	}

	async componentWillMount() {
		if (this.props.location.pathname === '/callback') {
			this.setState({ checkingSession: false });
			return;
		}
		try {
			await auth0Client.silentAuth();
			this.forceUpdate();
		} catch (err) {
			console.log(err);
			// TODO handle this?
			// if (err.error !== 'login_required') console.log(err.error);
		}
		this.setState({ checkingSession: false });
	}
	render() {
		return (
			<div>
				<NavBar />
				<Route exact path="/" component={Home} />
				<SecuredRoute path="/list" component={ItemCardList} checkingSession={this.state.checkingSession} />
				<SecuredRoute path="/daily" component={Cards} checkingSession={this.state.checkingSession} />
				<SecuredRoute path="/calendar" component={Calendar} checkingSession={this.state.checkingSession} />
				<SecuredRoute path="/now" component={Now} checkingSession={this.state.checkingSession} />
				<Route exact path="/callback" component={Callback} />
				<SecuredRoute
					path="/edit/:id"
					exact
					component={ItemEdit}
					checkingSession={this.state.checkingSession}
				/>
				<SecuredRoute path="/new" exact component={ItemEdit} checkingSession={this.state.checkingSession} />

				<SecuredRoute path="/chart" component={Chart} checkingSession={this.state.checkingSession} />
			</div>
		);
	}
}

export default withRouter(App);
