import React, { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Backspace';
import OkIcon from '@material-ui/icons/CheckCircleOutlined';
import './NumPad.css';

class NumPad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null
        };
        this.onDone = props.onDone;
        this.close = this.close.bind(this);
        
    }
    close() {
        this.props.onDone(this.state.value);
        this.setState({value: null})
    }
    numberClick(num) {
        if (num === 'x') {
            this.setState({value: null})
            return;
        }
        let newVal = this.state.value === null ? '' : this.state.value;
        newVal += num;
        this.setState({value: newVal})
    }

    render() {
        
        const numStyle = {
            width:'100%'
            , height:'100%'
            , fontSize: '40px'
        }
        return (
            <Dialog onClose={this.close} aria-labelledby="simple-dialog-title" open={this.props.open}>
                <DialogTitle id="simple-dialog-title">Input Value</DialogTitle>
                <div>
                    
                    <Grid container spacing={0}>
                        <Grid item xs={12} style={{textAlign:"center"}}>
                            <span style={{fontSize: '2em'}}>&nbsp;{this.state.value}</span>
                        </Grid>
                        <Grid item xs={4}>
                            <Button className="NumPad__number" onClick={() => this.numberClick('1')} variant="text" style={numStyle}>1</Button>
                        </Grid>
                        <Grid item xs={4}>
                        <Button onClick={() => this.numberClick('2')} variant="text" style={numStyle}>2</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button onClick={() => this.numberClick('3')} variant="text" style={numStyle}>3</Button>
                        </Grid>

                        <Grid item xs={4}><Button onClick={() => this.numberClick('4')} variant="text" style={numStyle}>4</Button></Grid>
                        <Grid item xs={4}><Button onClick={() => this.numberClick('5')} variant="text" style={numStyle}>5</Button></Grid>
                        <Grid item xs={4}><Button onClick={() => this.numberClick('6')} variant="text" style={numStyle}>6</Button></Grid>

                        <Grid item xs={4}><Button onClick={() => this.numberClick('7')} variant="text" style={numStyle}>7</Button></Grid>
                        <Grid item xs={4}><Button onClick={() => this.numberClick('8')} variant="text" style={numStyle}>8</Button></Grid>
                        <Grid item xs={4}><Button onClick={() => this.numberClick('9')} variant="text" style={numStyle}>9</Button></Grid>

                        <Grid item xs={4}><Button onClick={this.close} variant="text" style={numStyle} color="secondary"><OkIcon style={{fontSize:'40px'}}/></Button></Grid>
                        <Grid item xs={4}><Button onClick={() => this.numberClick('0')} variant="text" style={numStyle}>0</Button></Grid>
                        <Grid item xs={4}><Button onClick={() => this.numberClick('x')} variant="text" style={numStyle}><ClearIcon style={{fontSize:'40px'}}/></Button></Grid>
                    </Grid>
                </div>
            </Dialog>
        );
    }
}

export default NumPad;